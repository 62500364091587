export default [
  //{ id: "newtoAI", imageName: "newtoAI" },
  // { id: "howUseAI", imageName: "weuseAI" },
  //{ id: "weDetectCough", imageName: "detectcough" },
  { id: "imperialCollegeLondon", imageName: "coughbasealgorithm" },
  { id: "carnegieMellonUniversity", imageName: "coughsound" },
  //{ id: "carnegieMellonUniversity", imageName: "voicedetector" },
  { id: "MIT", imageName: "callforanewartificial" },
  //{ id: "various", imageName: "enabledPreliminary" },
  // { id: "universityOfEngineeringPakistan", imageName: "coughsound" },
  { id: "CornellUniversity", imageName: "exploringautomatic" },
];
